import PropTypes from 'prop-types'
import { useMemo } from 'react'

import useTranslation from '@sholdi/hooks/useTranslation'

import ErrorPageTemplate from './ErrorPageTemplate'

const ErrorPage = ({ errorCode, apiIsDown = false }) => {
  const { t } = useTranslation()

  const message = useMemo(() => {
    if (errorCode === 403) {
      return t('errorForbidden')
    }
    if (errorCode === 404) {
      return t('error404')
    }
    if (apiIsDown) {
      return t('serverIsDown')
    }
    return t('serverError')
  }, [errorCode, t, apiIsDown])

  return <ErrorPageTemplate message={message} code={errorCode} apiIsDown={apiIsDown} />
}

ErrorPage.propTypes = {
  errorCode: PropTypes.number,
  apiIsDown: PropTypes.bool,
}

ErrorPage.displayName = 'ErrorPage'

export default ErrorPage
