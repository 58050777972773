import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import ArrowLeft from '@sholdi/icons/ArrowLeft'

import useTranslation from '@sholdi/hooks/useTranslation'

import Button from '@sholdi/primitives/atoms/Button'
import Flex from '@sholdi/primitives/atoms/Flex'
import Image from '@sholdi/primitives/atoms/Image'
import Link from '@sholdi/primitives/atoms/Link'
import Text from '@sholdi/primitives/atoms/Text'

export default function ErrorPageTemplate({ message, code = 400, apiIsDown }) {
  const router = useRouter()
  const { t } = useTranslation()
  const errorCode = code.toString()

  return (
    <Flex className="min-h-[600px] h-screen w-full flex-col lg:flex-row justify-center items-center bg-unset shadow-none">
      <Flex className="w-full h-1/2 lg:w-1/2 flex flex-col px-8 md:px-24 pb-8 justify-center items-center">
        <Flex className="flex">
          <Text variant="h1" className="text-7xl font-bold">
            {errorCode[0]}
          </Text>
          <Flex className="w-14 h-14 rounded-full mt-2 mx-1.5 bg-secondary-main" />
          <Text variant="h1" className="text-7xl font-bold">
            {errorCode[2]}
          </Text>
        </Flex>
        <Text className="text-xl my-4">{message}</Text>
        {!apiIsDown && (
          <Flex className="w-full mt-8 justify-center">
            <Button
              variant="whiteGray"
              className="text-gray-700 font-medium"
              icon={ArrowLeft}
              buttonSize="lg"
              onClick={() => router.back()}
            >
              {t('back')}
            </Button>
            <Button as={Link} href="/" variant="primary" buttonSize="lg" className="ml-4">
              {t('index')}
            </Button>
          </Flex>
        )}
      </Flex>
      <Image
        src="/assets/images/ErrorPageImage.png"
        alt="error_image"
        layout="fill"
        className="w-full hidden lg:block relative h-full md:h-1/2"
      />
    </Flex>
  )
}

ErrorPageTemplate.propTypes = {
  message: PropTypes.string,
  code: PropTypes.number,
  apiIsDown: PropTypes.bool,
}
