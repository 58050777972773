import PropTypes from 'prop-types'


import ErrorPage from '../components/pageviews/ErrorPage'
import MainLayout from '../components/templates/MainLayout'
import { withServerSideProps } from '../lib/withServerSideProps'

const Error = ({ statusCode }) => (
  <MainLayout>
    <ErrorPage errorCode={statusCode} />
  </MainLayout>
)

Error.propTypes = {
  statusCode: PropTypes.number,
  layout: PropTypes.string,
}

export const getServerSideProps = withServerSideProps({
  getProps: async ctx => {
    const {
      res: { statusCode },
    } = ctx

    return { statusCode }
  },
})

export default Error
